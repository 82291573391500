import React, {useContext} from "react";
import {LabelContext} from "../../assets/js/labelDataContext";

const OrderSendFail = (props) => {
    const val = useContext(LabelContext);
    const isSend = val.labelInfo.isSend;

    return (
        <div className="row">
            <div className="col-12">
                <h1 className="text-center">Coś poszło nie tak...</h1><br/>
                <h4 className="text-center">Złożenie zamówienia nie powiodło się, prosimy skontaktuj się z nami mailowo pod adresem <a href="mailto:kontakt@barwy-zdrowia.pl">kontakt@barwy-zdrowia.pl</a> lub telefonicznie pod numerem <b>721 399 415</b> aby zweryfikować status zamówienia. <br/><br />Za utrudnienia najmocniej przepraszamy.</h4>
            </div>
        </div>
    );
};
export default OrderSendFail;