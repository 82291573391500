import * as React from "react"
// import {Link} from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import styled from "styled-components"
import {LabelProvider} from "../assets/js/labelDataContext"
import SmallHeroSection from "../components/SmallHeroSection";
import DecorativeSection from "../components/DecorativeSection";
import OrderSendFail from "../components/orders/OrderSendFail";

const OrderSendFailPage = () => (
    <Layout page="zamowieniefail">
        <Seo title="Zamówienie"/>

        <SmallHeroSection/>
        <DecorativeSection/>
        
        <section> 
          <LabelProvider>
            <OrderSendFail />
          </LabelProvider>
        </section> 

    </Layout>
)

export default OrderSendFailPage